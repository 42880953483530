import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Button, Modal } from 'reactstrap';
import { StyledCard, ActiveInactiveStatusBadge } from '../Base/Common/CommonUIComponents';
import { getInterfaceMode, getMarkupType, priceMarkupIncludePCOptions } from '../Base/Common/ReferenceDataFunctions';
import { CustomTable } from '../Base/Common/CustomTable';
import { DateRangePicker } from 'react-dates';
import { getAPI } from "../Base/API";
import moment from 'moment';

class RateCodeChannelHistory extends Component {

    constructor(props) {

        super(props);
        this.state = {
            error: [],
            block: true,
            historyList: null,
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
            startDate: null,
            endDate: null,
            interfaceModes: getInterfaceMode(),
            markupTypes: getMarkupType()
        };
    }

    componentDidMount() {
        if (this.props.rateCodeChannelId) {
            this.getRateCodeChannelHistory();
        }
    }

    getRateCodeChannelHistory = () => {
        var params = '';

        if (this.state.startDate && this.state.endDate) {
            params += `&startDate=${moment(this.state.startDate).format("YYYY-MM-DD")}&endDate=${moment(this.state.endDate).format("YYYY-MM-DD")}`
        }

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }

                this.setState({ historyList: data.data, totalItems: data.count, block: false });
            }
            else this.setState({ block: false });
        }, `/api/Rate/RateCodeChannel/v1/${this.props.rateCodeChannelId}/History?pageIndex=${this.state.pageIndex}&pageSize=${this.state.pageSize}` + params);
    }

    handleTableChange = (_, { page, sizePerPage }) => {
        this.setState({
            block: true,
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, this.getRateCodeChannelHistory);
    }

    doSearch = () => {
        this.setState({
            block: true,
            pageIndex: 0,
        }, this.getRateCodeChannelHistory );
    }

    render() {
        const { historyList, block, error } = this.state;

        const columns = [
            {
                dataField: 'updatedAt',
                text: this.props.intl.formatMessage({ id: "RateHistory.UpdatedAt" }),
                formatter: (cell) => cell ? <span> {moment(cell).format("YYYY-MM-DD HH:mm")} </span> : ''
            },
            {
                dataField: 'user',
                text: this.props.intl.formatMessage({ id: "RateHistory.UpdatedBy" })
            },
            {
                dataField: 'priceMode',
                text: this.props.intl.formatMessage({ id: "EditChannelMapping.PriceMode" }),
                formatter: (cell) => {
                    const priceMode = this.props.priceModes.find(el => el.value === cell);

                    return priceMode ? priceMode.label : ''
                }
            },
            {
                dataField: 'pricePosition',
                text: this.props.intl.formatMessage({ id: "EditChannelMapping.Position" })
            },
            {
                dataField: 'interfaceMode',
                text: this.props.intl.formatMessage({ id: "EditChannelMapping.Interface" }),
                formatter: (cell) => {
                    const interfaceMode = this.state.interfaceModes.find(el => el.value === cell);

                    return interfaceMode && interfaceMode.label
                }
            },
            {
                dataField: 'priceMarkupType',
                text: this.props.intl.formatMessage({ id: "EditChannelMapping.Markup" }),
                formatter: (cell, row) => {
                    const priceMarkupType = this.state.markupTypes.find(el => el.value === cell);
                    const pmipc = priceMarkupIncludePCOptions.find(el => el.value === row.priceMarkupIncludePackageComponents);

                    return priceMarkupType ? <span> {priceMarkupType.label} | {row.priceMarkupValue} | {pmipc && pmipc.label} </span> : ''
                }                
            },
            {
                dataField: 'rateCodeMapping',
                text: this.props.intl.formatMessage({ id: "ChannelList.channelRateCode" })
            },
            {
                dataField: 'roomTypeMapping',
                text: this.props.intl.formatMessage({ id: "ChannelList.channelRoomCode" })
            },
            {
                dataField: 'iCallUrl',
                text: this.props.intl.formatMessage({ id: "EditChannelMapping.ICalUrl" })
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "RateHistory.Active" }),
                formatter: (cell, row) => cell ? <ActiveInactiveStatusBadge status={cell} /> : ''
            }
        ];

        return (
            <StyledCard block={block} error={error} title="RateHistory.RateCodeChannelHistory">
                <Row className="mb-3">
                    <Col>
                        <DateRangePicker
                            startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : null}
                            startDateId="startDate"
                            isOutsideRange={day => day >= moment().add(1, 'days')}
                            endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                            endDateId="endDate"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col>
                        <Button className="float-right btn btn-sm btn-host" onClick={this.doSearch}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CustomTable
                            data={historyList || []}
                            columns={columns}
                            showTotal={true}
                            page={this.state.pageIndex + 1}
                            totalSize={this.state.totalItems}
                            remote={true}
                            sizePerPage={this.state.pageSize}
                            onTableChange={this.handleTableChange}
                            keyField='updatedAt'
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(RateCodeChannelHistory);