import React, { Component } from 'react';
import { injectIntl, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import { getAPI, postAPI, postMultiPartAPI } from "../../Base/API";
import { getOffersStatus, getExpTypes } from '../../Base/Common/ReferenceDataFunctions';
import ExperienceList from './ExperienceList';
import ExperienceDetail from './ExperienceDetail';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { handleNotification } from '../../Base/Notification';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

class Experiences extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            experiences: [],
            pageIndex: 0,
            pageSize: 9,
            totalItems: 0,
            selectedStatus: "Active",
            selectedExpType: null,
            statusOptions: getOffersStatus(),
            experiencesTypes: getExpTypes(this.props.intl),
            experienceDetail: null,
            sellItems: [],
            totalImagesSizes: 0,
        }
    }

    componentDidMount() {
        this.getExperiences()
    }

    getExperiences = () => {
        const { selectedStatus, selectedExpType, pageIndex, pageSize } = this.state;

        let statusBool = selectedStatus === "Active";

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data) {
                this.setState({ block: false, experiences: data.response, totalItems: data.count });
            }
            else {
                this.setState({ block: false, error: errorMessage });
            }
        }, `/api/hotel/Experience/v1/experience?pageSize=${pageSize}&pageIndex=${pageIndex}` + (selectedExpType ? `&type=${selectedExpType}` : ``) + (selectedStatus ? `&active=${statusBool}` : ``));
    }

    newExp = () => {
        this.setState({ experienceDetail: {} });
    }

    getExperienceDetail = (detailId) => {
        if (!detailId) {
            this.setState({ experienceDetail: null });
            return;
        }

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ block: false, error: errorMessage });
                    return;
                }
                if (data?.response) {
                    const experienceDetail = data.response[0];

                    if(experienceDetail.descriptions){
                        experienceDetail.descriptions.map(description => {
                            description.culture = description.cultureCode;
                            const blocksFromHtml = htmlToDraft(description.description);
                            const { contentBlocks, entityMap } = blocksFromHtml;
                            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                            description.editorState = EditorState.createWithContent(contentState);
                            return description;
                        });
                    }

                    this.setState({ experienceDetail, pageIndex: 0 }, () => this.getSellItems());
                }
                else {
                    this.setState({ block: false, error: errorMessage, pageIndex: 0 });
                }
            }, `/api/hotel/Experience/v1/experience/${detailId}`);
        });
    }

    getSellItems = () => {
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response) {
                    this.setState({ sellItems: data.response, block: false }, () => this.getExperiences());
                }
                else
                    this.setState(({ block: false }));
            }
        }, `/api/hotel/UpSellAndCrossSell/v1/sellItem?pageSize=999`);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    }

    handleSelectExpType = (type) => {
        this.setState({ selectedExpType: type?.value });
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getExperiences());
    }

    doSearch = () => {
        this.setState({
            pageIndex: 0,
            block: true
        }, () => this.getExperiences())
    }

    saveExperience = (e, experienceDetail) => {
        e.preventDefault();

        experienceDetail.minStay = parseInt(experienceDetail.minStay);
        experienceDetail.maxStay = parseInt(experienceDetail.maxStay);

        if (experienceDetail.image)
            experienceDetail.image.forEach((i) => i.order = parseInt(i.order));

        if (experienceDetail.sellItems)
            experienceDetail.sellItems.forEach((s) => s.quantity = parseInt(s.quantity));

        if (experienceDetail.active) {
            if (experienceDetail.active === "Active")
                experienceDetail.active = true;
            else if (experienceDetail.active === "Inactive")
                experienceDetail.active = false;
        }

        if(experienceDetail.descriptions){
            experienceDetail.descriptions.forEach(desc => {
                desc.description = desc.editorState && desc.editorState.getCurrentContent() ? draftToHtml(convertToRaw(desc.editorState.getCurrentContent())) : '';
            });
        }

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState(({ block: false, error: errorMessage }));
                        return;
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="Experience.ExperienceSaved" />, <FormattedMessage id="General.Success" />);
                        
                        this.setState({ error: errorMessage }, () => this.saveImage(data.response[0].id));
                    }
                    else {
                        this.setState({ error: errorMessage, block: false });
                    }
                }
            }, '/api/hotel/Experience/v1/experience', { request: experienceDetail });
        });
    }

    saveImage(experienceDetailId) {
        const { imgs } = this.state;

        if(imgs && imgs.length > 0){
            imgs.forEach((img, index) => {
                let attachments = { attachments: [] };
                let formData = new FormData();
                if (img && (img.file || img.id)) {
                    attachments.attachments.push({
                        Id: img.id,
                        FileName: img.file ? img.file.name : null,
                        Description: img.description,
                        Order: img.order
                    });
                    if(img.file && img.file.name){
                        formData.append("File", img.file, img.file?.name);
                    }
                }

                if(attachments && attachments.attachments && attachments.attachments.length > 0){
                    formData.append('Attachments', JSON.stringify(attachments));
                
                    postMultiPartAPI(result => {
                        if(index === imgs.length - 1){
                            const { data, error } = result;
                            var errorMessage = []
                            
                            if (error) {

                                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                                this.setState({ errorModal: errorMessage, block: false });
                                return;
                            }
                            if (data) {
                                if (data.errors && data.errors.length > 0) {
                                    handleNotification(data);
                                    this.setState({ block: false, error: errorMessage });
                                }
                                else {

                                    this.setState({ error: errorMessage, imgs: null, totalImagesSizes: 0}, () => this.getExperienceDetail(experienceDetailId));
                                }
                            }
                        }
                    }, `api/hotel/Experience/v1/${experienceDetailId}/photo/upload`, formData);
                }else{
                    if(index === imgs.length - 1){
                        if(experienceDetailId){
                            this.getExperienceDetail(experienceDetailId)
                        }else{
                            this.getExperiences();
                        }
                    }
                }
            })
        }else{
            this.getExperiences();
        }
    }

    associateImage = (imgs) => {
        this.setState({ imgs });
    }

    handleChangeImageGallerySize = (size) => {
        this.setState({ totalImagesSizes: size });
    }

    render() {
        const { experiences, pageIndex, pageSize, totalItems, selectedStatus, statusOptions, experienceDetail, error, block, experiencesTypes, sellItems } = this.state;
        const { rates } = this.props;
        return (
            <div>
                <StyledCard block={block} error={error} icon={'fas fa-umbrella-beach'} title={'navbar.experiences'} help={<FormattedHTMLMessage id="Experiences.Help" />} id="experiences">
                    {experienceDetail ?
                        <>
                            <div className="mb-2">
                                <Breadcrumb>
                                    <BreadcrumbItem>
                                        <a href="javascript:void(0);" onClick={() => this.getExperienceDetail()}>
                                            <FormattedMessage id="Experiences.List" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active>
                                        <FormattedMessage id="Experiences.Details" />
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </div>
                            <ExperienceDetail
                                getExperienceDetail={this.getExperienceDetail}
                                experienceDetail={experienceDetail}
                                saveExperience={this.saveExperience}
                                rates={rates}
                                sellItems={sellItems}
                                associateImage={this.associateImage}
                                handleChangeImageGallerySize={this.handleChangeImageGallerySize}
                                totalImagesSizes={this.state.totalImagesSizes}
                            />
                        </>
                        :
                        <ExperienceList
                            statusOptions={statusOptions}
                            handleSelect={this.handleSelect}
                            newExp={this.newExp}
                            selectedStatus={selectedStatus}
                            experiencesTypes={experiencesTypes}
                            handleSelectExpType={this.handleSelectExpType}
                            doSearch={this.doSearch}
                            totalItems={totalItems}
                            pageSize={pageSize}
                            handlePagination={this.handlePagination}
                            pageIndex={pageIndex}
                            experiences={experiences}
                            getExperienceDetail={this.getExperienceDetail}
                        />
                    }
                </StyledCard>
            </div>
        )
    }
}

export default injectIntl(Experiences)