import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge, Input, UncontrolledTooltip, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './NavMenu.css';
import './Mobile.css';
import { authContext} from '../Utils/AdalConfig';
import logo from '../img/heyCM.jpg';
import { getAPI, postAPI } from "./Base/API";
import BlockUi from 'react-block-ui';
import CustomSelect from './Base/Common/CustomSelect';
import  LanguageSwitch  from './profile/LanguageSwitch';
import Authorization, { CheckAuthorization } from './Base/Authorization';
import { ErrorAlert } from './Common/ErrorAlert';
import { SmallScreenUser } from './Base/Common/CommonUIComponents'
import tripadvisor from '../img/tripadvisor.ico';
import google from '../img/Google.ico';
import MenuEntry from './Common/MenuEntry';
import { getModules, getOperationModes, getPmsTypes, getRmsTypes } from './Base/Common/ReferenceDataFunctions';
import logobooking from '../img/booking.svg';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.refreshUserData = this.refreshUserData.bind(this);
        
        
        this.state = {
            collapsed: true,
            property: '',
            block: false,
            events: [],
            selectedProperty: authContext.getCachedUser()?.profile.city,
            notification: [{ text: 'Channel Booking with Mapping issues' }, { text: 'Syncronization errors in channel booking' }],
            allHotels: [],
            user: {},
            isMobile: false,
            propertyCombo: false,
        };
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);

        this.refreshUserData();

        if (CheckAuthorization("allProperties:changeProperty", null)) {
            this.getAllHotels();
        }

       /* const gtag = window.gtag;
       gtag('config', 'UA-157799755-1', {
            'user_id': ''+authContext.getCachedUser() && authContext.getCachedUser().profile && authContext.getCachedUser().profile.oid
        });
        console.log(authContext.getCachedUser().profile.oid)
       */
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        const screenWidth = document.documentElement.clientWidth || document.body.clientWidth;
        global.isMobile = screenWidth <= 991;
        var smallMenu = screenWidth <= 1366;
        this.setState({ isMobile: global.isMobile, smallMenu });
    }

    getAllHotels = () => {
        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                var list = [];
                if (data.data && data.data.length > 0) {
                    data.data.forEach(el => {
                        var property = {
                            value: el.hotelId,
                            label: el.name2
                        }
                        list.push(property);
                    })
                }
                this.setState({ block: false, allHotels: list });
            }
        }, '/api/hotel/v1');
    }

    getUserAllowProperties = () => {
        var list = [];
        if (this.state.user && this.state.user.userAllowedProperties) {
            this.state.user.userAllowedProperties.forEach(el => {
                var property = {
                    value: el.property,
                    label: el.propertyName
                }
                list.push(property);
            })
        }
        this.setState({ block: false, allowedHotels: list });
    }

    refreshUserData() {
        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const errorMessage = [];
                if (data.defaultPropertyActive === false) {
                    errorMessage.push({ message: <FormattedMessage id="NavMenu.HotelIsDeactivated" />, messageType: 'danger' });
                }

                global.channelList = data.channel??[];
                global.operationMode = data.operationMode;
                global.licenseLevel = data.licenseLevel;
                global.modules = data.modules ? data.modules : [];
                global.hotelCurrency = data.hotelCurrency;
                global.hotel = { id: data.defaultProperty, name: data.propertyName, hotelTimezone: data.hotelTimezone, chainName: data.chainName };
                global.PMSType = data.pmsType;
                global.RMSType = data.rmsType;
                global.reservationDelivery = data.reservationDelivery;
                global.sendRoomRate = data.sendRoomRate;
                global.defaultProperty = data.defaultProperty

                this.props.updateHasBEModule(global.modules ? global.modules.some(m => m === 'BookingEngine') : false);
                this.props.updateHasERModule(global.modules ? global.modules.some(m => m === 'MailReservation') : false);

                this.setState({ user: data, property: data.defaultProperty, block: false, error: errorMessage }, this.getUserAllowProperties);
            }
        },  '/api/User/v1/User');
    }

    changeDefaultProperty(evt) {
        let hotelguid = evt && evt.value ? evt.value : evt.target && evt.target.id;

        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
           
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ selectedProperty: hotelguid, block: false });

                window.location.reload();
            }
        }, '/api/User/v1/User/' + hotelguid +'/setPreferedHotel');
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
            personalArea: false
        });
    }

    logout() {
        const logoutiframe = document.getElementById("logoutiframe");
        logoutiframe.addEventListener('load', _ => {
            authContext.logOut();
        });
        logoutiframe.src = "https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=" + window.location.origin;
    }
    
    handleHotelFilter = (evt) => {
        const { value } = evt.target;

        var list = [...this.state.user.userAllowedProperties];

        if (value !== '') {
            for (let i = 0; i < list.length; i++) {
                if (list[i].propertyName && list[i].propertyName.toLowerCase().includes(value.toLowerCase())) {
                    list[i].visible = true;
                }
                else {
                    list[i].visible = false;
                }
            }
        }
        else {
            for (let i = 0; i < list.length; i++) { list[i].visible = true; }
        }

        this.setState({
            user: {
                ...this.state.user,
                userAllowedProperties: list
            }
        })
    }

    togglePersonalArea = () => {
        this.setState({
            personalArea: !this.state.personalArea,
            collapsed: true,
        });
    }

    toggleProperty = () => {
        this.setState({
            propertyCombo: !this.state.propertyCombo
        });
    }

    render() {
        const { user, error, isMobile, smallMenu } = this.state;
        const supportLink = process.env.REACT_APP_SUPPORT_URL;

        return (
            <Container className={`bg-light ${isMobile ? 'px-0' : ''}`}>
                <div style={{ display: 'block', visibility: 'hidden' }}>
                    <iframe
                        title="iframeNavMenu"
                        id="logoutiframe"
                        style={{ width: 0, height: 0, border: 0, visibility: 'hidden', position: 'absolute', left: 0, top: 0 }}
                        width="0"
                        height="0"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                    />
                </div>
                
                <Navbar expand={'lg'} id="navmenubar" sticky={'top'} className={`bg-white box-shadow shadow mb-2 font-weight-bold ${isMobile ? 'px-0' : 'max_navbar_height'}`}  style={{'borderBottom': '1px solid #165C7D'}} >
                    <NavbarBrand className={`${isMobile ? 'ml-3 p-0' : ''}`} tag="div">
                        <Link to="/">
                            <img src={logo} height={'45px'} alt="" />
                        </Link>
                    </NavbarBrand>
                    <div className='d-flex align-items-center'>
                        {isMobile ? 
                            <Button onClick={this.toggleNavbar} className="text-dark mx-2 py-0" style={{'border': 'none', 'backgroundColor': 'white'}}><i className="fas fa-bars"></i></Button>
                        :''}
                        {isMobile ?
                            <div className='mr-3'>
                                <SmallScreenUser
                                    func={this.togglePersonalArea}
                                />
                            </div>
                        :''}
                    </div>
                    <Collapse className={`d-lg-inline-flex flex-lg-row-reverse align-items-start ${isMobile ? 'bg-white p-3 mt-2' : 'h-100'}`} isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav ml-auto" style={{flexWrap: 'nowrap'}}>
                            {!isMobile ?
                                <>
                                    <UncontrolledDropdown nav inNavbar className={`${isMobile ? 'customnav-item' : ''}`}>
                                        {smallMenu ?
                                            <DropdownToggle nav className="pb-0">
                                                <SmallScreenUser
                                                    func={this.togglePersonalArea}
                                                />
                                            </DropdownToggle>
                                            :
                                            <>
                                                <DropdownToggle nav caret className="text-secondary pb-0">
                                                    {authContext.getCachedUser().userName??''}
                                                </DropdownToggle>
                                                <div className="font_size_xxxs text-secondary">
                                                    {user.propertyName}
                                                </div>
                                            </>
                                        }
                                    
                                        <DropdownMenu className={`${isMobile ? 'border-0' : ''}`}  right>
                                            <BlockUi tag="div" blocking={this.state.block}>
                                                <UncontrolledDropdown direction={`${isMobile ? 'Right' : 'left'}`}>
                                                    <DropdownToggle tag="a" className="dropdown-item" caret>
                                                        <FormattedMessage id="navbar.Property" />
                                                    </DropdownToggle>
                                                    
                                                    <Authorization
                                                        perform="allProperties:changeProperty"
                                                        yes={() => (
                                                            <DropdownMenu style={{ width: `${isMobile ? '' : '355px'}` , transform: `${isMobile ? '' : 'translate3d(-357px, -4px, 0px)'}`, margin: `${isMobile ? '20px' : ''}` , fontWeight: '400'}}>
                                                                <DropdownItem header>
                                                                    <FormattedMessage id="navbar.ChangeProperty" />
                                                                </DropdownItem>
                                                                <div className="mx-3">
                                                                    <CustomSelect
                                                                        isSearchable
                                                                        placeholder={""}
                                                                        options={this.state.allHotels}
                                                                        onChange={(combo) => this.changeDefaultProperty(combo)}
                                                                    />
                                                                </div>
                                                            </DropdownMenu>
                                                        )}
                                                        no={() => 
                                                            <DropdownMenu style={{ width: `${isMobile ? '' : '355px'}` , transform: `${isMobile ? '' : 'translate3d(-357px, -4px, 0px)'}`, margin: `${isMobile ? '20px' : ''}` , fontWeight: '400'}}>
                                                                <DropdownItem header>
                                                                    <FormattedMessage id="navbar.ChangeProperty" />
                                                                    {this.state.user ? <Input className="mt-2" type="text" name="searchHotel" onChange={(e) => this.handleHotelFilter(e)} placeholder="Hotel" /> : ''}
                                                                </DropdownItem>
                                                                {this.state.user && this.state.user.userAllowedProperties && this.state.user.userAllowedProperties.map((item, key) =>
                                                                    item.visible !== false ?
                                                                        <DropdownItem key={key} active={item.property === this.state.user.defaultProperty ? true : false} id={item.property} onClick={this.changeDefaultProperty.bind(this)}>{item.propertyName}</DropdownItem>
                                                                        : ''
                                                                )}
                                                            </DropdownMenu>
                                                        }
                                                    />

                                                </UncontrolledDropdown>
                                            </BlockUi>
                                            <LanguageSwitch mobileView={isMobile} />
                                            <DropdownItem tag={Link} to="/Profile" >
                                                <FormattedMessage id="navbar.Profile" />
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/Security" >
                                                <FormattedMessage id="navbar.Security" />
                                            </DropdownItem>
                                            <DropdownItem tag="a" href="https://hostpms.atlassian.net/wiki/spaces/CM/pages/277119029/Dashboard" target="_blank">
                                                <span > <i className="fas fa-external-link-alt mr-2"></i><FormattedMessage id="navbar.Help" /> </span>
                                            </DropdownItem>
                                            <DropdownItem tag="a" href={supportLink} target="_blank">
                                                <span > <i className="fas fa-headset mr-2" /><FormattedMessage id="navbar.Support" /> </span>
                                            </DropdownItem>
                                            
                                            <DropdownItem divider />
                                            <DropdownItem   >
                                                <div onClick={this.logout}><FormattedMessage id="navbar.logout" /> </div>
                                            </DropdownItem>

                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                    {!smallMenu ?
                                        <UncontrolledDropdown nav inNavbar className={`${isMobile ? 'customnav-item' : ''}`}>

                                            <DropdownToggle className="fas fa-inbox btn-lg" tag="a">

                                            </DropdownToggle>
                                            <Badge className="badge badge-notify ">{this.state.events && this.state.events.length}</Badge>
                                            <DropdownMenu className={`${isMobile ? 'border-0' : ''}`} right>
                                                <DropdownItem header><FormattedMessage id="navbar.LatestMessages" /></DropdownItem>
                                                {
                                                    this.state.events && this.state.events.map((item, key) =>
                                                        <DropdownItem key={key}>{item.eventType} {item.action} {item.channelErrors.length}</DropdownItem>
                                                    )
                                                }
                                            </DropdownMenu>

                                        </UncontrolledDropdown>
                                        : ''}
                                </>
                            :''}
                        </ul>
                        <ul className="navbar-nav mx-auto">
                            {!CheckAuthorization("bookingEngineOnly:view") ?
                                <>
                                    <UncontrolledDropdown nav inNavbar className={`${isMobile ? 'customnav-item' : ''} py-1`}>
                                        <DropdownToggle nav className="text-secondary" onClick={this.toggleNavbar} tag={Link} to="/">
                                            <FormattedMessage id="navbar.dashboard" />
                                        </DropdownToggle>

                                    </UncontrolledDropdown>
                                    <UncontrolledDropdown nav inNavbar className={`${isMobile ? 'customnav-item' : ''} py-1`}>
                                        <DropdownToggle nav caret className="text-secondary">
                                            <FormattedMessage id="navbar.PriceInventory" />
                                        </DropdownToggle>
                                        <DropdownMenu className={`${isMobile ? 'border-0' : ''}`} right>
                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/Inventory">
                                                <FormattedMessage id="navbar.Overview" />
                                            </DropdownItem>

                                            {!global.isMobile ?
                                                global.operationMode !== 'PmsFull' ?
                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/BulkUpdatePrices">
                                                        <FormattedMessage id="navbar.BulkUpdatePrices" />
                                                    </DropdownItem>
                                                :
                                                    global.modules?.some(m => m === 'PriceRules' || m === 'ComplexPriceRules') ?
                                                        <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/BulkUpdatePrices">
                                                            <FormattedMessage id="navbar.BulkUpdatePricesPMSFULL" />
                                                        </DropdownItem>
                                                    :''
                                            :''}
                                            {global.operationMode !== 'PmsFull' ?
                                                <div>
                                                    {!global.isMobile ?
                                                        <Authorization
                                                            perform="bulkUpdate:PricesAndRestrictions"
                                                            yes={() => (
                                                                <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/BulkUpdateRestrictions" >
                                                                    <FormattedMessage id="navbar.BulkUpdateRestrictions" />
                                                                </DropdownItem>
                                                            )}
                                                        />
                                                    :''}
                                                    <Authorization
                                                        perform="Inventory:ARI:Update"
                                                        yes={() => (
                                                            <MenuEntry
                                                                toggleNavbar={this.toggleNavbar}
                                                                id="navbar.MicroInventoryUpdate"
                                                                isNewPage={true}
                                                                isAdminOnly={false}
                                                                Link={Link}
                                                                to="/MicroInventoryUpdate"
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            :
                                                <div/>
                                            }

                                            {global.operationMode === 'None' && !global.isMobile ?
                                                <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/BulkUpdateAvailability" >
                                                    <FormattedMessage id="navbar.BulkUpdateAvailability" />
                                                </DropdownItem>
                                            : ''}

                                            <Authorization
                                                perform="yieldRules:view"
                                                yes={() => (
                                                    global.modules && global.modules.some(m => m === 'PriceRules') ?
                                                        <DropdownItem onClick={this.toggleNavbar} tag={Link} to={global.licenseLevel === 'ChannelExtras' ? '/RuleConfig' : '/UpgradePage'}>
                                                            <FormattedMessage id="navbar.Rules" />
                                                        </DropdownItem>
                                                        :
                                                        <DropdownItem onClick={this.toggleNavbar} tag={Link} to={'/UpgradePage'}  >
                                                            <FormattedMessage id="navbar.Rules" />
                                                        </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            {global.channelList.find(el => el === 'BOOKING') !== undefined ?
                                                <Authorization
                                                    perform="booking:promo:view"
                                                    yes={() => (
                                                        <DropdownItem onClick={this.toggleNavbar} tag={Link} to={global.licenseLevel === 'ChannelExtras' ? '/PromotionDashboard' : '/UpgradePage'}  >
                                                            <FormattedMessage id="navbar.Promotion" />
                                                        </DropdownItem>

                                                    )}
                                                    no={() => <div></div>}
                                                />
                                            : global.licenseLevel !== 'ChannelExtras' ?
                                                <Authorization
                                                    perform="booking:promo:view"
                                                    yes={() => (
                                                        <DropdownItem onClick={this.toggleNavbar} tag={Link} to={'/UpgradePage'}  >
                                                            <FormattedMessage id="navbar.Promotion" />
                                                        </DropdownItem>

                                                    )}
                                                    no={() => <div></div>}
                                                />
                                            : ''}

                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                    <UncontrolledDropdown nav inNavbar className={`${isMobile ? 'customnav-item' : ''} py-1`}>
                                        <DropdownToggle nav caret className="text-secondary">
                                            <FormattedMessage id="navbar.OnlineSetup" />
                                        </DropdownToggle>
                                        <DropdownMenu className={`${isMobile ? 'border-0' : ''}`} right>

                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ChannelDashboard">
                                                <FormattedMessage id="navbar.OnlineChannel" />
                                            </DropdownItem>

                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/LogsDashboard" >
                                                <FormattedMessage id="navbar.ChangeHistory" />
                                            </DropdownItem>

                                            {global.PMSType === getPmsTypes()[3].value || global.PMSType === getPmsTypes()[2].value || global.RMSType === getRmsTypes()[1].value || global.RMSType === getRmsTypes()[2].value ?
                                                <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/PMSLogs" >
                                                    <FormattedMessage id="navbar.PMSLogs" />
                                                </DropdownItem>
                                                : ''}

                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/CompareAvailability" >
                                                <FormattedMessage id="navbar.CompareAvailability" />
                                            </DropdownItem>

                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/PMSHistory" >
                                                <FormattedMessage id="navbar.PMSHistory" />
                                            </DropdownItem>
                                            {global.operationMode === 'PmsFull' ?
                                                <Authorization
                                                    perform="comparepmsdata: view"
                                                    yes={() => (
                                                        <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ComparePmsData" >
                                                            <FormattedMessage id="navbar.ComparePmsData" />
                                                            <i className="fas fa-exclamation-circle p-1" style={{ color: 'red' }} id="Operations" />
                                                            <UncontrolledTooltip placement="right" target="Operations" >
                                                                <FormattedMessage id="AdminIcon.Msg" />
                                                            </UncontrolledTooltip>
                                                        </DropdownItem>
                                                    )}
                                                    no={() => <div></div>}
                                                />
                                            : ''}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                    <UncontrolledDropdown nav inNavbar className={`${isMobile ? 'customnav-item' : ''} py-1`}>
                                        <DropdownToggle nav caret className="text-secondary">
                                            <FormattedMessage id="navbar.Reservations" />
                                        </DropdownToggle>
                                        <DropdownMenu className={`${isMobile ? 'border-0' : ''}`} right>
                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ReservationDashboard" >
                                                <FormattedMessage id="navbar.Arrivals" />
                                            </DropdownItem>

                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ReservationList">
                                                <FormattedMessage id="navbar.ReservationReport" />
                                            </DropdownItem>

                                            <Authorization
                                                perform="manualReservations:view"
                                                yes={() => (
                                                    global.modules && global.modules.some(m => m === 'ManualReservations') ?
                                                        <DropdownItem onClick={this.toggleNavbar} tag={Link} to={global.licenseLevel === 'ChannelExtras' ? '/ManualReservation' : '/UpgradePage'}>
                                                            <FormattedMessage id="navbar.NewReservation" />
                                                        </DropdownItem>
                                                        :
                                                        <DropdownItem onClick={this.toggleNavbar} tag={Link} to={'/UpgradePage'}  >
                                                            <FormattedMessage id="navbar.NewReservation" />
                                                        </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                    <Authorization
                                        perform="extras:view"
                                        yes={() => (
                                            <UncontrolledDropdown nav inNavbar className={`${isMobile ? 'customnav-item' : ''} py-1`}>
                                                <DropdownToggle nav caret className="text-secondary">
                                                    <FormattedMessage id="navbar.extras" />
                                                </DropdownToggle>
                                                <DropdownMenu className={`${isMobile ? 'border-0' : ''}`} right>
                                                    {global.channelList.find(el => el === 'AIRBNB' || el === 'BOOKING') !== undefined ?
                                                        <Authorization
                                                            perform="mailbox:view"
                                                            yes={() => (

                                                                <DropdownItem onClick={this.toggleNavbar} tag={Link} to={global.licenseLevel === 'ChannelExtras' ? '/MailDashboard' : '/UpgradePage'} >
                                                                    <FormattedMessage id="navbar.MailBox" />
                                                                </DropdownItem>

                                                            )}
                                                            no={() => <div></div>}
                                                        />
                                                    : global.licenseLevel !== 'ChannelExtras' ?
                                                            <Authorization
                                                                perform="mailbox:view"
                                                                yes={() => (
                                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to={'/UpgradePage'}>
                                                                        <FormattedMessage id="navbar.MailBox" />
                                                                    </DropdownItem>
                                                                )}
                                                                no={() => <div></div>}
                                                            />
                                                    : ''}

                                                    {global.channelList.find(el => el === 'BOOKING' || el === 'AIRBNB') !== undefined ?
                                                        <Authorization
                                                            perform="reviews:view"
                                                            yes={() => (
                                                                <DropdownItem onClick={this.toggleNavbar} tag={Link} to={global.licenseLevel === 'ChannelExtras' ? '/OnlineRatesDashboard' : '/UpgradePage'} >
                                                                    <FormattedMessage id="navbar.OnlineReputation" />
                                                                </DropdownItem>

                                                            )}
                                                            no={() => <div></div>}
                                                        />
                                                    : global.licenseLevel !== 'ChannelExtras' ?
                                                            <Authorization
                                                                perform="reviews:view"
                                                                yes={() => (
                                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to={'/UpgradePage'} >
                                                                        <FormattedMessage id="navbar.OnlineReputation" />
                                                                    </DropdownItem>

                                                                )}
                                                                no={() => <div></div>}
                                                            />
                                                    : ''}

                                                    {global.channelList.find(el => el === 'BOOKING' || el === 'AIRBNB') !== undefined ?
                                                        <Authorization
                                                            perform="booking:promo"
                                                            yes={() => (

                                                                <DropdownItem onClick={this.toggleNavbar} tag={Link} to={global.licenseLevel === 'ChannelExtras' ? '/OportunityDashboard' : '/UpgradePage'} >
                                                                    <FormattedMessage id="navbar.oportunity" />
                                                                </DropdownItem>

                                                            )}
                                                            no={() => <div></div>}
                                                        />
                                                    : global.licenseLevel !== 'ChannelExtras' ?
                                                            <Authorization
                                                                perform="booking:promo"
                                                                yes={() => (

                                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to={'/UpgradePage'} >
                                                                        <FormattedMessage id="navbar.oportunity" />
                                                                    </DropdownItem>

                                                                )}
                                                                no={() => <div></div>}
                                                            />
                                                    : ''}

                                                    {global.channelList.find(el => el === 'BOOKING') !== undefined ?
                                                        <Authorization
                                                            perform="booking:promo"
                                                            yes={() => (

                                                                <DropdownItem onClick={this.toggleNavbar} tag={Link} to={global.licenseLevel === 'ChannelExtras' ? '/PropertyScore' : '/UpgradePage'} >
                                                                    <FormattedMessage id="navbar.PropertyScore" />
                                                                </DropdownItem>

                                                            )}
                                                            no={() => <div></div>}
                                                        />
                                                        : global.licenseLevel !== 'ChannelExtras' ?
                                                            <Authorization
                                                                perform="booking:promo"
                                                                yes={() => (

                                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to={'/UpgradePage'} >
                                                                        <FormattedMessage id="navbar.PropertyScore" />
                                                                    </DropdownItem>

                                                                )}
                                                                no={() => <div></div>}
                                                            />
                                                            : ''}

                                                    {global.channelList.some(el => el === 'BOOKING') ?
                                                        <DropdownItem onClick={this.toggleNavbar} tag={Link} to={global.licenseLevel === 'ChannelExtras' ? '/MarketInsights' : '/UpgradePage'} >
                                                            <FormattedMessage id="navbar.MarketInsights" />
                                                        </DropdownItem>
                                                        : global.licenseLevel !== 'ChannelExtras' ?
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to={'/UpgradePage'} >
                                                                <FormattedMessage id="navbar.MarketInsights" />
                                                            </DropdownItem>
                                                            : ''}

                                                        <Authorization
                                                            perform="heyTravelAI:view"
                                                            yes={() => (
                                                                <DropdownItem onClick={this.toggleNavbar} tag={Link} to={'/HeyTravelAI'} >
                                                                    <FormattedMessage id="navbar.HeyTravelAI" /><i className="fas fa-exclamation-circle p-1" style={{ color: 'red' }} id="HeyTravelAI" />
                                                                    <UncontrolledTooltip placement="right" target="HeyTravelAI" >
                                                                        <FormattedMessage id="AdminIcon.Msg" />
                                                                    </UncontrolledTooltip>
                                                                </DropdownItem>
                                                            )}
                                                            no={() => ''}
                                                        />

                                                    {/*
                                            <DropdownItem onClick={this.toggleNavbar} disabled>
                                                <FormattedMessage id="navbar.RateShopper" />
                                            </DropdownItem>
                                            <DropdownItem onClick={this.toggleNavbar} disabled>
                                                <FormattedMessage id="navbar.Cross_Services" />
                                            </DropdownItem>
                                                */}

                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        )}
                                        no={() => <div></div>}
                                    />

                                    <UncontrolledDropdown nav inNavbar className={`${isMobile ? 'customnav-item' : ''} py-1`}>
                                        <DropdownToggle nav className="text-secondary" onClick={this.toggleNavbar} tag={Link} to="/AnalyticsDashboard">
                                            <FormattedMessage id="navbar.Analytics" />
                                        </DropdownToggle>
                                    </UncontrolledDropdown>

                                    <UncontrolledDropdown nav inNavbar className={`${isMobile ? 'customnav-item' : ''} py-1`}>
                                        <DropdownToggle nav caret className="text-secondary">
                                            <FormattedMessage id="navbar.HotelSettings" />
                                        </DropdownToggle>
                                        <DropdownMenu className={`${isMobile ? 'border-0' : ''}`} right>
                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/PropertySetup" >
                                                <FormattedMessage id="navbar.Global" />
                                            </DropdownItem>
                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ComplexList" >
                                                <FormattedMessage id="navbar.Property" />
                                            </DropdownItem>
                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/RoomCategorySetup" >
                                                <FormattedMessage id="navbar.RoomCategory" />
                                            </DropdownItem>
                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/PackageList" >
                                                <FormattedMessage id="navbar.PackageSetup" />
                                            </DropdownItem>
                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/RoomRateConfig" >
                                                <FormattedMessage id="navbar.RoomsAndRates" />
                                            </DropdownItem>
                                            {/*<DropdownItem onClick={this.toggleNavbar} tag={Link} to="/RoomSetup" >
                                        <FormattedMessage id="navbar.PhysicalRoom" />
                                    </DropdownItem>
                                    */}

                                            {/* <Authorization
                                        perform="PriceListManagment:view"
                                        yes={() => (

                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/PriceListManagement" >
                                                <FormattedMessage id="navbar.PriceListManagement" />
                                            </DropdownItem>

                                        )}
                                        no={() => <div></div>}
                                    /> */}

                                            <Authorization
                                                perform="configuration:accouting:view"
                                                yes={() => (
                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/Accounting" >
                                                        <FormattedMessage id="navbar.Accounting" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />


                                            <Authorization
                                                perform="manageusers:edit"
                                                yes={() => (
                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ManageUser" >
                                                        <FormattedMessage id="navbar.ManageUser" />
                                                    </DropdownItem>

                                                )}
                                                no={() => <div></div>}
                                            />

                                            <Authorization
                                                perform="cancellationPolicy:hasHeyBe"
                                                yes={() => (
                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/CancellationPolicy" >
                                                        <FormattedMessage id="navbar.CancelationPolicy" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            {global.modules && global.modules.some(m => m === 'BookingEngine') ?
                                                <Authorization
                                                    perform="offers:view"
                                                    yes={() => (
                                                        <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/Offers" >
                                                            <FormattedMessage id="navbar.Offers" />
                                                        </DropdownItem>
                                                    )}
                                                    no={() => <div></div>}
                                                />
                                                : ''}

                                            {global.modules && global.modules.some(m => m === 'BookingEngine') ?
                                                <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/PromoCodes" >
                                                    <FormattedMessage id="navbar.PromoCodes" />
                                                </DropdownItem>
                                                : ''}

                                            {
                                                global.modules && global.modules.some(m => m === 'CurrencyConvertion') && global.operationMode !== getOperationModes()[0].value || global.operationMode === getOperationModes()[0].value && global.sendRoomRate ?
                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/CurrencyConversion" >
                                                        <FormattedMessage id="navbar.CurrencyConversion" />
                                                    </DropdownItem>
                                                    :
                                                    ''
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </>
                            : ''}

                            {global.modules && global.modules.some(m => m === 'BookingEngine') ?
                                <UncontrolledDropdown nav inNavbar className={`${isMobile ? 'customnav-item' : ''} py-1`}>
                                    <DropdownToggle nav caret className="text-secondary ">
                                        <FormattedMessage id="navbar.BookingEngine" />
                                    </DropdownToggle>
                                    <DropdownMenu className={`${isMobile ? 'border-0' : ''}`}>
                                        <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/BEAnalytics" >
                                            <FormattedMessage id="navbar.BEAnalitics" />
                                        </DropdownItem>
                                        <DropdownItem tag={Link} onClick={this.toggleNavbar} to="/BEConfigDashboard" >
                                            <FormattedMessage id="navbar.BEConfigDashboard" />
                                        </DropdownItem>
                                        <DropdownItem onClick={this.toggleNavbar} tag={Link} target="_blank" to={process.env.REACT_APP_BE_URL + global.hotel.id} >
                                            Website
                                        </DropdownItem>
                                        <Authorization
                                            perform="admin:view"
                                            yes={() => (
                                                global.modules && global.modules.includes("BookingEngineChain") && user.chainName ?
                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} target="_blank" to={process.env.REACT_APP_BE_URL + user.chainName} >
                                                        <FormattedMessage id="navbar.ChainWebsite" />
                                                        <i className="fas fa-exclamation-circle p-1" style={{ color: 'red' }} id="chainWebsite" />
                                                        <UncontrolledTooltip placement="right" target="chainWebsite" >
                                                            <FormattedMessage id="AdminIcon.Msg" />
                                                        </UncontrolledTooltip>
                                                    </DropdownItem>
                                                    : ''
                                            )}
                                        />
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            : ''}

                            <Authorization
                                perform="admin:view"
                                yes={() => (
                                    global.modules && global.modules.some(m => m === 'MailReservation') ?
                                        <UncontrolledDropdown nav inNavbar className={`${isMobile ? 'customnav-item' : ''} py-1`}>
                                            <DropdownToggle onClick={this.toggleNavbar} nav className="text-secondary " tag={Link} to="/EmailReservationDashboard">
                                                <FormattedMessage id="navbar.EmailReservationDashboard" />
                                                <i className="fas fa-exclamation-circle p-1" style={{ color: 'red' }} id="EmailReservationDashboard" />
                                                <UncontrolledTooltip placement="right" target="EmailReservationDashboard" >
                                                    <FormattedMessage id="AdminIcon.Msg" />
                                                </UncontrolledTooltip>
                                            </DropdownToggle>
                                        </UncontrolledDropdown>
                                    : ''
                                )}
                            />

                            {!CheckAuthorization("bookingEngineOnly:view") ?
                                <>
                                    {global.modules && global.modules.some(m => m === 'Payments') ?
                                        <Authorization
                                            perform="paymentMenu:view"
                                            yes={() => (
                                                <UncontrolledDropdown nav inNavbar className={`${isMobile ? 'customnav-item' : ''} py-1`}>
                                                    <DropdownToggle nav caret className="text-secondary ">
                                                        <FormattedMessage id="navbar.Payment" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className={`${isMobile ? 'border-0' : ''}`}>
                                                        <Authorization
                                                            perform="paymentGateway:view"
                                                            yes={() => (
                                                                <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/PaymentGateway" >
                                                                    <FormattedMessage id="navbar.PaymentGateway" />
                                                                </DropdownItem>
                                                            )}
                                                            no={() => <div></div>}
                                                        />
                                                        <Authorization
                                                            perform="payments:view"
                                                            yes={() => (
                                                                <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/Payments" >
                                                                    <FormattedMessage id="navbar.Payments" />
                                                                </DropdownItem>
                                                            )}
                                                            no={() => <div></div>}
                                                        />
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            )}
                                            no={() => <div></div>}
                                        />
                                        : ''}

                                    <Authorization
                                        perform="admin:view"
                                        yes={() => (
                                            <UncontrolledDropdown nav inNavbar className={`${isMobile ? 'customnav-item' : ''} py-1`}>
                                                <DropdownToggle nav caret className="text-secondary ">
                                                    <FormattedMessage id="navbar.Admin" />
                                                </DropdownToggle>
                                                <DropdownMenu className={`${isMobile ? 'border-0' : ''}`}>
                                                    <UncontrolledDropdown nav={true} inNavbar direction="right" className="pl-0" tag="ul">
                                                        <DropdownItem>
                                                            <i className="fas fa-sync-alt mr-1"></i><FormattedMessage id="navbar.sync" /> <i className="float-right fas fa-caret-right pt-1"> </i>
                                                        </DropdownItem>
                                                        <DropdownMenu className={`${isMobile ? 'border-0' : ''}`}>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/AdminHome">
                                                                <i className="fas fa-chart-pie"></i> <FormattedMessage id="navbar.overview" />
                                                            </DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/SyncEvents">
                                                                <i className="fas fas fa-sync-alt"></i> <FormattedMessage id="navbar.SyncEvents" />
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    <DropdownItem divider />
                                                    <UncontrolledDropdown nav={true} inNavbar direction="right" className="pl-0" tag="ul">
                                                        <DropdownItem>
                                                            <i className="far fa-clipboard mr-1"></i> <FormattedMessage id="navbar.config" /> <i className="float-right fas fa-caret-right pt-1"> </i>
                                                        </DropdownItem>
                                                        <DropdownMenu className={`${isMobile ? 'border-0' : ''}`}>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ConfigChannelAutoActions"><i className="fas fa-robot mr-1"></i><FormattedMessage id="navbar.ChannelErrorsAction" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/Announcement"><i className="fas fa-bullhorn mr-1"></i><FormattedMessage id="navbar.Announcement" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ManageChannelConfig"><i className="far fa-clipboard mr-1"></i> <FormattedMessage id="navbar.ChannelConfig" /></DropdownItem>
                                                            {/*<DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ManageChannelInstance"><i className="fas fa-plug mr-1"></i> <FormattedMessage id="navbar.ChannelInstance" /></DropdownItem>*/}
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/Notification"><i className="fas fa-envelope mr-1"></i> <FormattedMessage id="navbar.NotificationTemplate" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/CreateEmail"><i className="fas fa-envelope mr-1"></i> <FormattedMessage id="navbar.CreateEmail" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ConfigLicenses"><i className="fas fa-file-alt mr-1"></i> <FormattedMessage id="navbar.ConfigLicenses" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ResetCache"><i className="fas fa-database mr-1"></i> <FormattedMessage id="navbar.ResetCache" /></DropdownItem>
                                                            {
                                                                global.modules && global.modules.some(m => m === 'BookingEngine') ?
                                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/CityTaxConfig"><i className="fas fa-hand-holding-usd mr-1"></i> <FormattedMessage id="navbar.CityTaxConfig" /></DropdownItem>
                                                                    :
                                                                    ''
                                                            }
                                                            <UncontrolledDropdown nav={true} inNavbar direction="right" className="pl-0" tag="ul">
                                                                <DropdownItem>
                                                                    <i className="fas fa-plug mr-1"></i> <FormattedMessage id="navbar.Channels" /> <i className="float-right fas fa-caret-right pt-1"> </i>
                                                                </DropdownItem>
                                                                <DropdownMenu className={`${isMobile ? 'border-0' : ''}`}>
                                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/BookingConnections">
                                                                        <img alt="" src={logobooking} height="20px"></img>
                                                                        <FormattedMessage id="navbar.Booking.com" />
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>

                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/MailExtractorTemplates" >
                                                                <i className="fas fa-envelope-open-text mr-1"/>
                                                                <FormattedMessage id="navbar.MailExtractorTemplate" />
                                                            </DropdownItem>

                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/AIPrompts" >
                                                                <i className="fas fa-envelope-open-text mr-1"/>
                                                                <FormattedMessage id="navbar.AIPrompts" />
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    <DropdownItem onClick={this.toggleNavbar} divider />
                                                    <UncontrolledDropdown nav={true} inNavbar direction="right" className="pl-0" tag="ul">
                                                        <DropdownItem>
                                                            <i className="fas fa-file-medical-alt mr-1"></i><FormattedMessage id="navbar.healtcheck" />  <i className="float-right fas fa-caret-right pt-1"> </i>
                                                        </DropdownItem>
                                                        <DropdownMenu className={`${isMobile ? 'border-0' : ''}`}>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/DirtyDataDashboard"> <i className="fas fa-hourglass-end mr-2"></i><FormattedMessage id="navbar.dirtyData" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/QueueStatusDashboard"><i className="fas fa-briefcase mr-1"></i> <FormattedMessage id="navbar.queueStatus" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/SystemEvents"><i className="fas fa-exclamation-circle mr-1"></i> <FormattedMessage id="navbar.systemEvents" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/InvalidReservationDashboard"><i className="fas fa-skull mr-1"></i> <FormattedMessage id="navbar.InvalidReservations" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ReservationTimerDashboard"><i className="fas fa-history mr-1"></i> <FormattedMessage id="navbar.ReservationTimer" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ReservationStatusDashboard"><i className="fas fa-history mr-1"></i> <FormattedMessage id="navbar.ReservationStatusDashboard" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/DBCoherence"><i className="fas fa-not-equal mr-1"></i> <FormattedMessage id="navbar.DBCoherence" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/HotelDataUpdates"><i className="fas fa-wifi mr-1"></i> <FormattedMessage id="navbar.HotelDataUpdates" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ErrorEvents"><i className="fas fa-exclamation-triangle mr-1"></i> <FormattedMessage id="navbar.ErrorEvents" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/AutomatedActions"><i className="fas fa-robot mr-1"></i> <FormattedMessage id="navbar.AutomatedActions" /></DropdownItem>
                                                            <Authorization
                                                                perform="recordsByEntityName:view"
                                                                yes={() => (
                                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/RecordsByEntityType"><i className="fas fa-database mr-1"></i> <FormattedMessage id="navbar.RecordsByEntityType" /></DropdownItem>
                                                                )}
                                                                no={() => <div></div>}
                                                            />
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/DeliveryTime"><i className="fas fa-hourglass-half mr-1"></i> <FormattedMessage id="navbar.DeliveryTime" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ApiGatewayUsage"><i className="fas fa-file-signature mr-1"></i> <FormattedMessage id="navbar.ApiGatewayUsage" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/PMSQueues"><i className="fas fa-external-link-alt mr-1"></i> <FormattedMessage id="navbar.PMSQueues" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/CheckMappings"><i className="fas fa-random mr-1"></i> <FormattedMessage id="navbar.CheckMappings" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/PlatformDeepDive"><i className="far fa-chart-bar mr-1"></i> <FormattedMessage id="navbar.PlatformDeepDive" /></DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    <DropdownItem onClick={this.toggleNavbar} divider />
                                                    <UncontrolledDropdown nav={true} inNavbar direction="right" className="pl-0" tag="ul">
                                                        <DropdownItem>
                                                            <i className="fas fa-wrench mr-1"></i><FormattedMessage id="navbar.Maintenance" />  <i className="float-right fas fa-caret-right pt-1"> </i>
                                                        </DropdownItem>
                                                        <DropdownMenu className={`${isMobile ? 'border-0' : ''}`}>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/DataClean">
                                                                <i className="fas fa-trash mr-1"></i><FormattedMessage id="navbar.DataClean" />
                                                            </DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/HotelFullPush">
                                                                <i className="fas fa-hotel mr-1"></i><FormattedMessage id="navbar.HotelFullPush" />
                                                            </DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/RunningProcesses">
                                                                <i className="fas fa-database mr-1"></i><FormattedMessage id="navbar.RunningProcesses" />
                                                            </DropdownItem>
                                                            <Authorization
                                                                perform="admin:checkPasswords"
                                                                yes={() => (
                                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/CheckPasswords">
                                                                        <i className="fas fa-key mr-1"></i><FormattedMessage id="navbar.CheckPasswords" />
                                                                    </DropdownItem>
                                                                )}
                                                                no={() => <div></div>}
                                                            />
                                                            
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>


                                                    <DropdownItem onClick={this.toggleNavbar} divider />
                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ManageScheduleTask"> <i className="fas fa-list mr-1"></i><FormattedMessage id="navbar.ManageScheduleTask" /></DropdownItem>
                                                    <DropdownItem onClick={this.toggleNavbar} divider />
                                                    <UncontrolledDropdown nav={true} inNavbar direction="right" className="pl-0" tag="ul">
                                                        <DropdownItem>
                                                            <i className="fas fa-hotel mr-1"></i><FormattedMessage id="navbar.hotelGlobalData" />  <i className="float-right fas fa-caret-right pt-1"> </i>
                                                        </DropdownItem>
                                                        <DropdownMenu className={`${isMobile ? 'border-0' : ''}`}>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/HotelChannelInfo"> <i className="fas fa-hotel mr-1"></i><FormattedMessage id="navbar.hotelChannelInfo" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/HotelLicenses"> <i className="fas fa-hotel mr-1"></i><FormattedMessage id="navbar.hotelLicenses" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/HotelList"> <i className="fas fa-hotel mr-1"></i><FormattedMessage id="navbar.Hotel" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/HotelGroup"> <i className="fas fa-hotel mr-1"></i><FormattedMessage id="navbar.HotelGroup" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/HotelBulkLoad"><i className="fas fa-file-upload mr-1"></i> <FormattedMessage id="navbar.HotelBulkLoad" /></DropdownItem>

                                                            <Authorization
                                                                perform="upgradeRequests:view"
                                                                yes={() => (
                                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/UpgradeRequests"><i className="fas fa-arrow-alt-circle-up mr-1"></i> <FormattedMessage id="navbar.UpgradeRequests" /></DropdownItem>
                                                                )}
                                                                no={() => <div></div>}
                                                            />

                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ConfigCategoriesAndAvailability"><i className="fas fa-bed mr-1"></i> <FormattedMessage id="navbar.ConfigCategoriesAndAvailability" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ChangeOperationMode"><i className="fas fa-cog mr-1"></i> <FormattedMessage id="navbar.ChangeOperationMode" /></DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/PaymentGatewaysByHotel"><i className="fas fa-credit-card mr-1"></i> <FormattedMessage id="navbar.PaymentGatewaysByHotel" /></DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    <DropdownItem onClick={this.toggleNavbar} divider />

                                                    <UncontrolledDropdown nav={true} inNavbar direction="right" className="pl-0" tag="ul">
                                                        <DropdownItem>
                                                            <i className="fas fa-frog mr-1"  />
                                                            <FormattedMessage id="navbar.Sapo" />
                                                            <i className="float-right fas fa-caret-right pt-1"> </i>
                                                        </DropdownItem>
                                                        <DropdownMenu className={`${isMobile ? 'border-0' : ''}`}>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/OTAManagement" >
                                                                <i className="fas fa-frog mr-1"/><FormattedMessage id="navbar.CommissionConfig" />
                                                            </DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/SAPOReservations" >
                                                                <i className="far fa-calendar-alt mr-1" /><FormattedMessage id="navbar.SAPOReservations" />
                                                            </DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/SAPOBilling" >
                                                                <i className="fas fa-file-invoice mr-2" /><FormattedMessage id="navbar.SAPOBilling" />
                                                            </DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/SAPOClients" >
                                                                <i className="fas fa-users mr-1" /><FormattedMessage id="navbar.SAPOClients" />
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>

                                                    <DropdownItem onClick={this.toggleNavbar} divider />
                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ImportHotelConfig" >
                                                        <i className="fas fa-upload mr-1"></i><FormattedMessage id="navbar.ImportHotelConfig" />
                                                    </DropdownItem>
                                                    <DropdownItem onClick={this.toggleNavbar} divider />
                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/AdminDashboard" >
                                                        <i className="fas fa-bars mr-1"></i><FormattedMessage id="navbar.AdminDashboard" />
                                                    </DropdownItem>
                                                    <DropdownItem onClick={this.toggleNavbar} divider />

                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/RNTProperties" >
                                                        <i className="fas fa-building  mr-1"></i><FormattedMessage id="navbar.RNTProperties" />
                                                    </DropdownItem>
                                                    <DropdownItem onClick={this.toggleNavbar} divider />
                                                    <UncontrolledDropdown nav={true} inNavbar direction="right" className="pl-0" tag="ul">
                                                        <DropdownItem>
                                                            <i className="fas fa-user mr-1" /> <FormattedMessage id="navbar.ManageUser" /> <i className="float-right fas fa-caret-right pt-1"> </i>
                                                        </DropdownItem>
                                                        <DropdownMenu className={`${isMobile ? 'border-0' : ''}`}>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ApiGatewayUserManagement" >
                                                                <i className="fas fa-user-cog  mr-1" /><FormattedMessage id="navbar.ApiGatewayUserManagement" />
                                                            </DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/AdminUserManagement" >
                                                                <i className="fas fa-user  mr-1" /><FormattedMessage id="navbar.AdminUserManagement" />
                                                            </DropdownItem>
                                                            <Authorization
                                                                perform="adminUserEmailReport:view"
                                                                yes={() => (
                                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/AdminUserEmailReport" >
                                                                        <i className="fas fa-at mr-1" /><FormattedMessage id="navbar.AdminUserEmailReport" />
                                                                    </DropdownItem>
                                                                )}
                                                                no={() => <div></div>}
                                                            />
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>

                                                    <DropdownItem onClick={this.toggleNavbar} divider />
                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/NewChannelPassword" >
                                                        <i className="fas fa-key mr-1"></i><FormattedMessage id="navbar.NewChannelPassword" />
                                                    </DropdownItem>

                                                    <DropdownItem onClick={this.toggleNavbar} divider />
                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ManagePayments" >
                                                        <i className="fas fa-credit-card mr-1"></i><FormattedMessage id="navbar.ManagePayments" />
                                                    </DropdownItem>

                                                    <DropdownItem onClick={this.toggleNavbar} divider />
                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/PMSHistoryAdmin" >
                                                        <i className="fas fa-history mr-1"></i><FormattedMessage id="navbar.PMSHistory" />
                                                    </DropdownItem>

                                                    <Authorization
                                                        perform="analyticsAdmin:view"
                                                        yes={() => (
                                                            <>
                                                                <DropdownItem onClick={this.toggleNavbar} divider />
                                                                <UncontrolledDropdown nav={true} inNavbar direction="right" className="pl-0" tag="ul">
                                                                    <DropdownItem>
                                                                        <i className="far fa-chart-bar mr-1"></i> <FormattedMessage id="navbar.AnalyticsAdmin" /> <i className="float-right fas fa-caret-right pt-1"> </i>
                                                                    </DropdownItem>
                                                                    <DropdownMenu className={`${isMobile ? 'border-0' : ''}`}>
                                                                        <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ReservationGlobalData">
                                                                            <i className="fas fa-globe mr-1"></i><FormattedMessage id="navbar.ReservationGlobalData" />
                                                                        </DropdownItem>
                                                                        <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ReservationByNationality">
                                                                            <i className="fas fa-flag mr-1"></i><FormattedMessage id="navbar.ReservationByNationality" />
                                                                        </DropdownItem>
                                                                        <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ClientReservationsSummary">
                                                                            <i className="fas fa-globe-americas mr-1"></i><FormattedMessage id="navbar.ClientReservationsSummary" />
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </>
                                                        )}
                                                        no={() => <div></div>}
                                                    />

                                                    <DropdownItem onClick={this.toggleNavbar} divider />
                                                    <UncontrolledDropdown nav={true} inNavbar direction="right" className="pl-0" tag="ul">
                                                        <DropdownItem>
                                                            <i className="fas fa-angle-left" /><i className="fas fa-angle-right mr-1" /> <FormattedMessage id="navbar.Metasearch" /> <i className="float-right fas fa-caret-right pt-1"> </i>
                                                        </DropdownItem>
                                                        <DropdownMenu className={`${isMobile ? 'border-0' : ''}`}>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/Google">
                                                                <img src={google} className="mr-1" height="16px" alt="Google" /> Google
                                                            </DropdownItem>
                                                            <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/Tripadvisor">
                                                                <img src={tripadvisor} className="mr-1" height="18px" alt="Tripadvisor" /> Tripadvisor
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    <DropdownItem onClick={this.toggleNavbar} divider />
                                                    <DropdownItem onClick={this.toggleNavbar} tag={Link} to="/ManageBeData">
                                                        <i className="fas fa-wrench mr-1"></i><FormattedMessage id="navbar.ManageBeData" />
                                                    </DropdownItem>
                                                </DropdownMenu>

                                            </UncontrolledDropdown>
                                        )}
                                        no={() => <div></div>}
                                    />
                                </>
                            : ''}

                        </ul>

                    </Collapse>
                    {isMobile ?
                        <Collapse className={`d-lg-inline-flex flex-lg-row-reverse bg-white p-3 mt-2 w-100`} isOpen={this.state.personalArea}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <LanguageSwitch mobileView={isMobile}/>
                                </div>
                                <div>
                                    <UncontrolledDropdown direction={'left'}>
                                        <DropdownToggle tag="a" className="p-0 d-flex align-items-center justify-content-center dropdown-item" caret={CheckAuthorization("allProperties:changeProperty") || this.state.allowedHotels?.length > 1}>
                                            <div className='ml-2'>
                                                <div className="text-secondary pb-0">
                                                    {authContext.getCachedUser().userName??''}
                                                </div>
                                                <div className="font_size_xxxs text-secondary">
                                                    {user.propertyName}
                                                </div>
                                            </div>
                                        </DropdownToggle>
                                        {CheckAuthorization("allProperties:changeProperty") ?
                                            <BlockUi tag="div" blocking={this.state.block}>
                                                <DropdownMenu isOpen={true} style={{ position: 'absolute', right: '2vw', width: '60vw', marginTop: '4vh' }}>
                                                    <DropdownItem header>
                                                        <FormattedMessage id="navbar.ChangeProperty" />
                                                    </DropdownItem>
                                                    <div className="mx-3 font-weight-normal">
                                                        <CustomSelect
                                                            isSearchable
                                                            placeholder={""}
                                                            options={this.state.allHotels}
                                                            onChange={(combo) => this.changeDefaultProperty(combo)}
                                                        />
                                                    </div>
                                                    {/* {this.state.allHotels.map((h, k) => 
                                                        <DropdownItem key={k} onClick={_ => this.changeDefaultProperty(h)} className="py-2">
                                                            {h.label}
                                                        </DropdownItem>
                                                    )} */}
                                                </DropdownMenu>
                                            </BlockUi>
                                            :
                                            <BlockUi tag="div" blocking={this.state.block}>
                                                <DropdownMenu isOpen={true} style={{ position: 'absolute', right: '2vw', width: '60vw', marginTop: '4vh' }}>
                                                    <DropdownItem header>
                                                        <FormattedMessage id="navbar.ChangeProperty" />
                                                    </DropdownItem>
                                                    <div className="mx-3 font-weight-normal">
                                                        <CustomSelect
                                                            isSearchable
                                                            placeholder={""}
                                                            options={this.state.allowedHotels}
                                                            onChange={(combo) => this.changeDefaultProperty(combo)}
                                                        />
                                                    </div>
                                                </DropdownMenu>
                                            </BlockUi>
                                        }
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                            <div className='text-left text-secondary pr-2'>
                                <div className='mt-2'>
                                    <Link to="/Profile" onClick={this.togglePersonalArea} style={{ color: 'unset', textDecoration: 'none' }}>
                                        <FormattedMessage id="navbar.Profile" />
                                    </Link>
                                </div>
                                <div className='mt-2 text-secondary'>
                                    <Link to="/Security" onClick={this.togglePersonalArea} style={{ color: 'unset', textDecoration: 'none' }}>
                                        <FormattedMessage id="navbar.Security" />
                                    </Link>
                                </div>
                                <div className='mt-2 text-secondary'>
                                    <a onClick={this.togglePersonalArea} href="https://hostpms.atlassian.net/wiki/spaces/CM/pages/277119029/Dashboard" target="_blank" rel="noopener noreferrer" style={{ color: 'unset', textDecoration: 'none' }}>
                                        <span>
                                            <i className="fas fa-external-link-alt mr-2"></i>
                                            <FormattedMessage id="navbar.Help"/>
                                        </span>
                                    </a>
                                </div>
                                <div className='mt-2 text-secondary'>
                                    <a onClick={this.togglePersonalArea} href={supportLink} target="_blank" rel="noopener noreferrer" style={{ color: 'unset', textDecoration: 'none' }}>
                                        <span>
                                            <i className="fas fa-headset mr-2"/>
                                            <FormattedMessage id="navbar.Support"/>
                                        </span>
                                    </a>
                                </div>
                                <div className='w-100 text-secondary d-flex align-items-center justify-content-start mt-2 pt-2'>
                                    <div className="pt-2 pr-4" style={{ width: 'fit-content', borderTop: '1px solid #c9c9c9' }} onClick={this.logout}>
                                        <FormattedMessage id="navbar.logout"/>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    :''}
                </Navbar>
                <ErrorAlert error={error} icon="fas fa-times-circle" />
            </Container>

        );
    }

}