import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Row, Col, Input, Label, CardHeader, Button, CustomInput } from 'reactstrap';
import { getAPI, postAPI, putAPI } from '../../Base/API';
import { SelectHotel } from "../../Base/Common/CommonUIComponents";
import { getModules } from "../../Base/Common/ReferenceDataFunctions";
import CustomSelect from '../../Base/Common/CustomSelect';
import { handleNotification } from "../../Base/Notification";
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../Common/ErrorAlert";
import Authorization from '../../Base/Authorization';
import { ConfigCredentialsForm } from './ConfigCredentialsForm';
import { ChangeSimplePassword } from './ChangeSimplePassword';

export class HotelLicenseDetail extends Component {

    constructor(props) {
        super(props);
        this.handleSave = this.handleSave.bind(this);
        this.hotelLicenseForm = React.createRef();
        this.configCredentialsForm = React.createRef();
        this.configCredentialsPasswordForm = React.createRef();
        this.handleHotelFieldChange = this.handleHotelFieldChange.bind(this)
        this.handleChangeOnConfigCredentials = this.handleChangeOnConfigCredentials.bind(this);
        this.handleChangeOnConfigStatus = this.handleChangeOnConfigStatus.bind(this);
        this.saveConfigCredentials = this.saveConfigCredentials.bind(this);
        this.state = {
            block: true,
            modalData: this.props.modalData,
            configCredentials: {
                active: false,
                hotelId: this.props.modalData.hotel.hotelId,
                availability: false,
                price: false,
                cta: false,
                ctd: false,
                maxStay: false,
                minStay: false,
                stopSales: false,
                rmsAvailability: false,
                rmsPrice: false,
                rmsCta: false,
                rmsCtd: false,
                rmsMaxStay: false,
                rmsMinStay: false,
                rmsStopSales: false
            },
            changePasswordModal: false,
            passwordField: null,
            chainModal: false,
            pmsData: ["availability", "price", "cta", "ctd", "maxStay", "minStay", "stopSales"]
        }
    }

    componentDidMount() {
        const { configCredentials } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                this.setState({ error: errorMessage, block: false, configCredentials: data.response[0] });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/hotel/v1/hotelExternalPmsNotification?hotelId=${configCredentials.hotelId}`);
    }

    handleFieldChange = (name, combo) => {
        const value = combo ? combo.value : null;

        this.setState(prevState => (
            {
                modalData: {
                    ...prevState.modalData,
                    [name]: value
                }
            })
        );
    }

    handlePmsTypeChange = (combo, evt) => {
        if (evt) {
            const value = combo ? combo.value : null;

            this.setState(prevState => (
                {
                    modalData: {
                        ...prevState.modalData,
                        pmsType: value
                    }
                }));
        }
    }

    handleRmsTypeChange = (combo, evt) => {
        if (evt) {
            const value = combo ? combo.value : null;

            this.setState(prevState => (
                {
                    modalData: {
                        ...prevState.modalData,
                        rmsType: value
                    }
                }));
        }
    }

    handleChainChange = (combo, evt) => {
        if (evt) {
            const { modalData } = this.state
            const value = combo ? combo.value : null;

            if (modalData.modules.includes("BookingEngine") && modalData.modules.includes("BookingEngineChain")) {
                if (value === "NEW") {
                    this.setState({ chainModal: true })
                }
                else {
                    this.setState(prevState => (
                        {
                            modalData: {
                                ...prevState.modalData,
                                chainId: value
                            }
                        }));
                }
            } 
        }
    }

    getNewConfigCredentials = () => {
        const { configCredentials } = this.state;

        this.setState({ block: true });
        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                this.setState({ error: errorMessage, block: false, configCredentials: data.response[0] });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/hotel/v1/hotelExternalPmsNotification/${configCredentials.hotelId}`);
    }

    handleMultiFieldChange = (name, combo) => {
        const value = combo !== null ? combo.map(el => el.value) : [];
        this.setState(prevState => (
            {
                modalData: {
                    ...prevState.modalData,
                    [name]: value
                }
            })
        );
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        var inputValue = value.replace(/[^a-z0-9]/gi, '');

        this.setState(prevState => ({
            modalData: {
                ...prevState.modalData,
                [name]: inputValue
            }
        }));
    }

    handleSwitch = (evt) => {
        const { id, checked } = evt.target;

        this.setState(prevState => ({
            modalData: {
                ...prevState.modalData,
                [id]: checked
            }
        }));
    }

    handleLicenseFieldChange = (name, combo) => {
        const value = combo ? combo.value : null;
        const license = this.props.licenses && this.props.licenses.find(license => license.value == value);
        
        this.setState(prevState => (
            {
                modalData: {
                    ...prevState.modalData,
                    license: {
                        ...prevState.modalData.license,
                        id: license && license.value,
                        numChannels: license && license.numChannels,
                        numRooms: license && license.numRooms,
                        licenseLevel: license && license.licenseLevel
                    }
                }
            })
        );
    }

    handleHotelFieldChange = (name, combo) => {
        const value = combo ? combo.value : null;
        this.setState(prevState => (
            {
                modalData: {
                    ...prevState.modalData,
                    hotel: {
                        ...prevState.modalData.hotel,
                        hotelId: value
                    }
                }
            })
        );
    }

    handleSave() {
        if (this.hotelLicenseForm.current.reportValidity()) {
            this.setState({ block: true });

            var body = {...this.state.modalData };
            if (body.licensedRooms) body.licensedRooms = parseInt(body.licensedRooms);

            postAPI(result => {
                const { data, error, isLoading } = result;              
                this.setState({ block: isLoading });
                if (error) {
                    const errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    handleNotification(data, <FormattedMessage id="HotelLicenseDetail.SaveMessageSuccess" />, <FormattedMessage id="General.Success" />);
                    this.setState({ chainModal: false }, () => this.props.updateHotelLicense(data.hotelLicense));
                }
            }, '/api/hotel/v1/hotelLicense', JSON.stringify(body));
        }
    }

    handleChangeOnConfigCredentials(evt) {
        if (evt && evt.target) {
            const { configCredentials } = this.state;
            const { name, value } = evt.target;
            configCredentials[name] = value;
            this.setState({ configCredentials });
        }
    }

    handleChangeOnConfigStatus(evt) {
        if (evt && evt.target) {
            const { configCredentials } = this.state;
            const { name, checked } = evt.target;
            configCredentials[name] = checked;
            this.setState({ configCredentials });
        }
    }

    handleChangeOnConfigInfo = (name) => {
        const { configCredentials } = this.state;
        configCredentials[name] = !configCredentials[name];
        this.setState({ configCredentials });
    }

    saveConfigCredentials() {
        const { modalData, configCredentials, pmsData } = this.state;

        //New Hotel Validations
        if (modalData.pmsType === this.props.pmsTypes[3].value && modalData.operationMode !== this.props.operationModes[0].value) {
            const data = { warnings: [] };

            //Limited - Apenas pode receber disponibilidade
            if (modalData.operationMode === this.props.operationModes[1].value && pmsData.slice(1).some(el => configCredentials[el])) {
                data.warnings.push({ message: 'Operation mode is PMS Limited. NewHotel only supports availability' });
            }
            //None - Apenas pode receber reservas não recebe preços, restrições, disponibilidade
            else if (modalData.operationMode === this.props.operationModes[2].value && pmsData.some(el => configCredentials[el]) ) {
                data.warnings.push({ message: "Operation mode is None. NewHotel only receive reservations" });
            }

            if (data.warnings.length > 0) {
                handleNotification(data);
                return;
            }
        }

        if (this.configCredentialsForm.current.reportValidity()) {
            this.saveConfigCredentialsRequest();
        }
    }

    saveConfigCredentialsPassword = () => {
        if (this.configCredentialsPasswordForm.current.reportValidity()) {
            this.saveConfigCredentialsRequest();
        }
    }

    saveConfigCredentialsRequest = () => {
        const { configCredentials } = this.state;
        const request = {
            request: configCredentials
        };
        this.setState({ block: true });
        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(({ block: false, error: errorMessage }));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="HotelLicenseDetail.SaveConfigCredentialsMessageSuccess" />, <FormattedMessage id="General.Success" />);
                    Object.assign(configCredentials, data.response[0]);
                    this.setState(({ block: false, error: errorMessage, configCredentials }));
                }
                else {
                    this.setState(({ block: false, error: errorMessage }));
                }
            }
            else {
                this.setState(({ block: false, error: errorMessage }));
            }
        }, '/api/hotel/v1/hotelExternalPmsNotification', request);
    }

    toggleChangePasswordModal = (passwordField) => {
        this.setState(prevState => ({ changePasswordModal: !prevState.changePasswordModal, passwordField: passwordField }));
    }

    togglechainModal = () => {
        this.setState(prevState => ({ chainModal: !prevState.chainModal }));
    }    


    render() {
        const { pmsTypes, rmsTypes, hotelChains } = this.props;
        const { configCredentials, modalData, changePasswordModal, passwordField, chainModal, pmsData } = this.state;

        const licenseLevels = [
            {
                value: 'ChannelExtras',
                label: this.props.intl.formatMessage({ id: "HotelLicenseDetail.ChannelExtras" })
            },
            {
                value: 'Connector',
                label: this.props.intl.formatMessage({ id: "HotelLicenseDetail.Connector" })
            }
        ]

        let modules;
        if (modalData.hotel.beConfigurationMode !== "SinglePage"){
            modules = getModules(this.props.intl).filter(function (obj) {
                return obj.value !== "BookingEngineChain";
            });
        } else modules = getModules(this.props.intl);

        const hotelChainsOptions = [{value: "NEW", label: <b><FormattedMessage id="HotelLicenses.AddNew"/></b>}, ...hotelChains];

        const hasModulesBE = (modalData.modules.includes("BookingEngine") && modalData.modules.includes("BookingEngineChain"));

        return (
            <>
                <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} fade={false} className="modal-lg">
                    <ModalHeader toggle={this.props.toggleModal}>
                        {this.props.title}
                    </ModalHeader>
                    <ModalBody className="modal-lg p-0">
                        <BlockUi tag="div" blocking={this.state.block}>
                            <ErrorAlert error={this.state.error} />
                            <Card className="shadow border-0 h-100">
                                <CardHeader>                                
                                    <FormattedMessage id="HotelLicenseDetail.Intro" />
                                    <strong>
                                        {this.state.modalData.hotel && this.state.modalData.hotel.name1}
                                    </strong>

                                    <Authorization
                                        perform="hotelLicenseDetail:save"
                                        yes={() => (
                                            <Button color=" btn-host btn-sm float-right" onClick={this.handleSave}>
                                                <i className="fas fa-save" />
                                            </Button>
                                        )}
                                        no={() => <div></div>}
                                    />
                                </CardHeader>
                                <CardBody>
                                    <form ref={this.hotelLicenseForm}>
                                        <Row className="pt-2">
                                            <Col className="col-1">
                                                <Label for="hotelId">
                                                    <FormattedMessage id="HotelLicenses.HotelId" />
                                                </Label>
                                            </Col>
                                            <Col className="col-5">
                                                <SelectHotel name={'hotelId'} icon={'fas fa-hotel'} onChangeFunc={this.handleHotelFieldChange} placeholder={<FormattedMessage id="HotelLicenses.Hotel" />} value={this.state.modalData.hotel.hotelId} />
                                            </Col>
                                            <Col className="col-2">
                                                <Label for="operationMode">
                                                    <FormattedMessage id="HotelLicenses.OperationMode" />
                                                </Label>
                                            </Col>
                                            <Col className="col-4">
                                                <CustomSelect name={'operationMode'} options={this.props.operationModes} icon={'fas fa-wrench'} onChange={this.handleFieldChange.bind(this, 'operationMode')} placeholder={<FormattedMessage id="HotelLicenses.OperationMode" />} value={this.props.operationModes && this.props.operationModes.find(operationMode => operationMode.value == this.state.modalData.operationMode)} isClearable />
                                            </Col>
                                        </Row>
                                        <Row className="pt-2">
                                            <Col className="col-1">
                                                <Label for="isLocalPms">
                                                    <FormattedMessage id="HotelLicenses.IsLocalPms" />
                                                </Label>
                                            </Col>
                                            <Col className="col-5">
                                                <CustomSelect name={'isLocalPms'} options={this.props.isLocalPms} icon={'fas fa-check'} onChange={this.handleFieldChange.bind(this, 'isLocalPms')} placeholder={<FormattedMessage id="HotelLicenses.IsLocalPms" />} value={this.props.isLocalPms && this.props.isLocalPms.find(isLocalPms => isLocalPms.value == this.state.modalData.isLocalPms)} isClearable required/>
                                            </Col>
                                            <Col className="col-2">
                                                <Label>
                                                    <FormattedMessage id="HotelLicenses.GoogleAnalyticsId" />
                                                </Label>
                                            </Col>
                                            <Col className="col-4">
                                                <Input
                                                    type="text" className="text-sm"
                                                    name="googleAnalyticsId"
                                                    onChange={this.handleChange}
                                                    value={this.state.modalData && this.state.modalData.googleAnalyticsId ? this.state.modalData.googleAnalyticsId : ''}
                                                    required={this.state.modalData.modules && this.state.modalData.modules.some(m => m === 'GoogleAnalytcs') ? true : false}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="pt-2">
                                            <Col className="col-1">
                                                <FormattedMessage id="HotelLicenses.PmsType" />
                                            </Col>
                                            <Col className="col-5">
                                                <CustomSelect name={'pmsType'} options={this.props.pmsTypes} onChange={this.handlePmsTypeChange.bind(this)} placeholder={<FormattedMessage id="HotelLicenses.PmsType" />} value={this.props.pmsTypes && this.props.pmsTypes.find(p => p.value == this.state.modalData.pmsType)} isClearable required />
                                            </Col>
                                            <Col className="col-2">
                                                <FormattedMessage id="HotelLicenses.ReservationDelivery" />
                                            </Col>
                                            <Col className="col-4">
                                                <CustomSelect name={'reservationDelivery'} options={this.props.reservationDeliveries} onChange={this.handleFieldChange.bind(this, 'reservationDelivery')} placeholder={<FormattedMessage id="HotelLicenses.ReservationDelivery" />} value={this.props.reservationDeliveries && this.props.reservationDeliveries.find(p => p.value == this.state.modalData.reservationDelivery)} isClearable required />
                                            </Col>
                                        </Row>
                                        <Row className="pt-2">
                                            <Col className="col-1">
                                                <FormattedMessage id="HotelLicenses.RmsType" />
                                            </Col>
                                            <Col className="col-5">
                                                <CustomSelect name={'rmsType'} options={this.props.rmsTypes} onChange={this.handleRmsTypeChange.bind(this)} placeholder={<FormattedMessage id="HotelLicenses.RmsType" />} value={this.props.rmsTypes && this.props.rmsTypes.find(p => p.value == this.state.modalData.rmsType)} isClearable required />
                                            </Col>
                                            <Col className="col-2">
                                                <Label for="licenseId">
                                                    <FormattedMessage id="HotelLicenses.LicensedRooms" />
                                                </Label>
                                            </Col>
                                            <Col className="col-4">
                                                <Input
                                                    type="number"
                                                    className="text-sm"
                                                    name="licensedRooms"
                                                    value={this.state.modalData?.licensedRooms || ''}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 align-items-center">
                                            <Col className="col-6">
                                                {hasModulesBE ?
                                                    <Row>
                                                        <Col className="col-2 pr-0">
                                                            <Label>
                                                                <FormattedMessage id="HotelLicenses.hotelChain" />
                                                            </Label>
                                                        </Col>
                                                        <Col className="col-10">
                                                            <CustomSelect
                                                                name={'chainId'}
                                                                options={hotelChainsOptions}
                                                                onChange={this.handleChainChange.bind(this)}
                                                                placeholder={<FormattedMessage id="HotelLicenses.hotelChain" />}
                                                                value={this.state.modalData.chainId && hotelChainsOptions.find(p => p.value == this.state.modalData.chainId)}
                                                                isClearable={true}
                                                                isCreatable={true}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <Row>
                                                    </Row>
                                                }
                                            </Col>
                                            <Col>
                                                <Label>
                                                    <FormattedMessage id="HotelLicenses.SendCMRoomRate" />
                                                </Label>
                                            </Col>
                                            <Col className="col-1 text-right">
                                                <CustomInput
                                                    type="switch"
                                                    id="sendCMRoomRate"
                                                    checked={this.state.modalData?.sendCMRoomRate}
                                                    onChange={this.handleSwitch}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="pt-2">
                                            <Col className="col-1">
                                                <Label for="licenseId">
                                                    <FormattedMessage id="HotelLicenses.License" />
                                                </Label>
                                            </Col>
                                            <Col className="col-5">
                                                <CustomSelect name={'licenseId'} options={this.props.licenses} icon={'fas fa-file-alt'} onChange={this.handleLicenseFieldChange.bind(this, 'id')} placeholder={<FormattedMessage id="HotelLicenses.License" />} value={this.props.licenses && this.props.licenses.find(license => license.value == this.state.modalData.license.id)} isClearable />
                                            </Col>
                                            <Col className="col-2">
                                                <Label for="numChannels">
                                                    <FormattedMessage id="HotelLicenses.NumChannels" />
                                                </Label>
                                            </Col>
                                            <Col className="col-4">
                                                <Input type="Text" className="text-sm " value={this.state.modalData.license && this.state.modalData.license.numChannels ? this.state.modalData.license.numChannels : ''} disabled />
                                            </Col>
                                        </Row>
                                        <Row className="pt-2">
                                            <Col className="col-6">
                                            </Col>
                                            <Col className="col-2">
                                                <Label for="numRooms">
                                                    <FormattedMessage id="HotelLicenses.NumRooms" />
                                                </Label>
                                            </Col>
                                            <Col className="col-4">
                                                <Input type="Text" className="text-sm " value={this.state.modalData.license && this.state.modalData.license.numRooms ? this.state.modalData.license.numRooms : ''} disabled />
                                            </Col>
                                        </Row>
                                        <Row className="pt-2">
                                            <Col className="col-6" />
                                            <Col className="col-2">
                                                <Label>
                                                    <FormattedMessage id="HotelLicenses.LicenseLevel" />
                                                </Label>
                                            </Col>
                                            <Col className="col-4">
                                                <Input type="Text" className="text-sm" value={this.state.modalData.license && this.state.modalData.license.licenseLevel ? licenseLevels.find(level => level.value === this.state.modalData.license.licenseLevel).label : ''} disabled />
                                            </Col>
                                        </Row>

                                        <Row className="pt-2 pb-5">
                                            <Col className="col-1">
                                                <Label>
                                                    <FormattedMessage id="HotelLicenses.Modules" />
                                                </Label>
                                            </Col>
                                            <Col className="col-11">
                                                <CustomSelect
                                                    options={modules}
                                                    onChange={this.handleMultiFieldChange.bind(this, 'modules')}
                                                    placeholder={<FormattedMessage id="HotelLicenses.Modules" />}
                                                    value={this.state.modalData.modules ? modules.filter(opt => this.state.modalData.modules.find(s => opt.value === s) !== undefined) : ''}
                                                    isClearable
                                                    isMulti={true}
                                                />
                                            </Col>
                                        </Row>
                                    </form>
                                    {
                                        //Only shows credentials form when PMS Type is equal to 'BookingSpaceBeds', 'NewHotel' or RMS Type is different from 'None'
                                        modalData.pmsType === pmsTypes[2].value || modalData.pmsType === pmsTypes[3].value || (modalData.rmsType === rmsTypes[1].value || modalData.rmsType === rmsTypes[2].value) ?
                                            <form ref={this.configCredentialsForm}>
                                                {
                                                    changePasswordModal ?
                                                        <ChangeSimplePassword
                                                            isOpen={changePasswordModal}
                                                            toggle={this.toggleChangePasswordModal}
                                                            configCredentials={configCredentials}
                                                            handleChangeOnConfigCredentials={this.handleChangeOnConfigCredentials}
                                                            saveConfigCredentials={this.saveConfigCredentialsPassword}
                                                            form={this.configCredentialsPasswordForm}
                                                            passwordField={passwordField}
                                                        />
                                                        :
                                                        <div />
                                                }
                                                <ConfigCredentialsForm
                                                    configCredentials={configCredentials}
                                                    pmsData={pmsData}
                                                    handleChangeOnConfigCredentials={this.handleChangeOnConfigCredentials}
                                                    handleChangeOnConfigStatus={this.handleChangeOnConfigStatus}
                                                    saveConfigCredentials={this.saveConfigCredentials}
                                                    handleChangeOnConfigInfo={this.handleChangeOnConfigInfo}
                                                    getNewConfigCredentials={this.getNewConfigCredentials}
                                                    toggleChangePasswordModal={this.toggleChangePasswordModal}
                                                    isDisabled={configCredentials.id !== undefined}
                                                    hasPmsType={modalData.pmsType === pmsTypes[2].value || modalData.pmsType === pmsTypes[3].value}
                                                    hasRmsType={modalData.rmsType === rmsTypes[1].value || modalData.rmsType === rmsTypes[2].value}
                                                    pmsType={modalData.pmsType !== pmsTypes[3].value}
                                                />
                                            </form>
                                            :
                                            <div />
                                    }
                                </CardBody>
                            </Card>
                        </BlockUi>
                    </ModalBody>
                </Modal>
                <Modal isOpen={chainModal} toggle={this.togglechainModal} fade={false} className="modal-md" style={{ minWidth: 0, marginTop: '15%' }}>
                    <ModalBody>
                        <div>
                            <Authorization
                                perform="hotelLicenseDetail:save"
                                yes={() => (
                                    <Button color=" btn-host btn-sm float-right" onClick={this.handleSave}>
                                        <i className="fas fa-save" />
                                    </Button>
                                )}
                                no={() => <div></div>}
                            />
                        </div>
                        <div>
                            <Label>
                                <b><FormattedMessage id="HotelLicenses.newHotelChain"/></b>
                            </Label>
                            <Input
                                type="text" className="text-sm mt-2"
                                name="chain"
                                onChange={this.handleChange}
                                value={this.state.modalData && this.state.modalData.chain ? this.state.modalData.chain : ''}
                                required={true}
                            />
                        </div>
                    </ModalBody>
                </Modal>               
            </>
        );
    }
}

export default injectIntl(HotelLicenseDetail)